<template>
    <div>
        <nl-loader v-if="loading" :show="true" />
        <div v-else>
            <router-link to="/client/preorder" class="text-decoration-none">
                <button class="button is-white text-dark is-small mb-2">
                    <i class="fas fa-backward mr-1" />
                    {{ $t('commande.back') }}
                </button>
            </router-link>

            <div class="box">
                <div v-if="projects.length > 0">
                    <div>
                        {{
                            $t('commande.remainingpreorders', {
                                remainingPreorders: remainingPreorders
                            })
                        }}
                    </div>
                    <hr />
                    <div
                        v-for="(keyword, index) in keywords"
                        :key="`keyword_${index}`"
                        class="d-flex flex-column"
                        style="gap: 1rem"
                    >
                        <div class="fields-wrapper">
                            <div class="d-flex flex-column" style="gap: 1rem">
                                <b-field :label="$t('commande.mainkeyword')">
                                    <b-input v-model="keyword.main_keyword" :placeholder="$t('commande.keyword')" />
                                </b-field>
                                <b-field :label="$t('commande.secondarykeyword')">
                                    <div class="d-flex flex-column" style="gap: 0.5rem">
                                        <b-input
                                            v-for="(secondary_keyword, i) in keyword.secondary_keywords"
                                            :key="`keyword_${index}.${i}`"
                                            v-model="secondary_keyword.name"
                                            :placeholder="i + 1 + '/5'"
                                            @input="
                                                i === keyword.secondary_keywords.length - 1 &&
                                                    addSecondaryKeyword(index)
                                            "
                                        />
                                    </div>
                                </b-field>
                            </div>
                            <div class="d-flex flex-column" style="gap: 1rem">
                                <b-field :label="$t('language.title')">
                                    <b-select v-model="keyword.lang" expanded>
                                        <option value="fr">
                                            {{ $t('language.French') }}
                                        </option>
                                        <option value="en">
                                            {{ $t('language.English') }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field :label="$t('commande.maincategory')">
                                    <b-select v-model="keyword.group_id" expanded>
                                        <option v-for="group in groups" :key="group.id" :value="group.id">
                                            {{ group.name }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field :label="$t('commande.numberarticles')">
                                    <b-input v-model="keyword.articles_number" type="number" placeholder="Ex: 5" />
                                </b-field>
                            </div>
                            <b-field :label="$t('commande.addnote')">
                                <b-input
                                    v-model="keyword.notes"
                                    type="textarea"
                                    style="min-height: 5rem; min-width: 20rem"
                                    expanded
                                />
                            </b-field>
                        </div>
                        <div v-if="index !== 0" class="d-flex justify-content-end">
                            <b-button
                                icon-left="trash"
                                type="is-danger"
                                size="is-small"
                                :label="$t('global.delete')"
                                @click="deleteKeyword(index)"
                            />
                        </div>
                        <hr />
                    </div>
                    <div class="d-flex justify-content-end" style="gap: 1rem">
                        <b-button
                            v-if="remainingPreorders > 0"
                            icon-left="plus-circle"
                            type="is-warning"
                            @click="addKeyword"
                        >
                            {{ $t('commande.addkeyword') }}
                        </b-button>
                        <b-button icon-left="save" type="is-warning" @click="saveData">
                            {{ $t('global.send') }}
                        </b-button>
                    </div>
                </div>
                <div v-else>
                    <p class="mt-5 text-secondary">
                        {{ $t('commande.noproject') }}
                    </p>
                </div>
            </div>
            <Toaster ref="toaster" />
        </div>
    </div>
</template>

<script>
    import Toaster from '../components/UI/Toaster'

    import 'bootstrap/js/src/tooltip'
    import { mapState } from 'vuex'

    export default {
        name: 'Commande',
        title: 'layout.preorders',
        components: {
            Toaster
        },
        data: function () {
            return {
                keywords: [
                    {
                        main_keyword: '',
                        secondary_keywords: [{ name: '' }],
                        lang: 'fr',
                        group_id: 1,
                        articles_number: 1,
                        notes: ''
                    }
                ],
                groups: [],
                id: 0,
                maxMainKeywords: 10,
                loading: false,
                preloading: false,
                appUrl: '',
                shouldShowSaveButton: false
            }
        },
        computed: {
            ...mapState({
                project: 'project'
            }),
            ...mapState('project_selector', {
                projects: 'projects'
            }),
            remainingPreorders() {
                if (this.project) {
                    return this.maxMainKeywords - this.project.preorders_count - this.keywords.length
                }
                return 0
            }
        },
        watch: {
            project(val) {
                this.keywords.forEach(keyword => {
                    keyword.group_id = val.group_id
                    keyword.lang = val.project_lang
                })
            }
        },
        created() {
            this.axios.get('/crud/groups?orderBy=name').then(response => {
                this.groups = response.data.groups
            })
        },
        mounted: function () {},
        methods: {
            addKeyword() {
                if (this.remainingPreorders >= 1) {
                    this.keywords.push({
                        main_keyword: '',
                        secondary_keywords: [{ name: '' }],
                        lang: this.project.project_lang,
                        group_id: this.project.group_id,
                        articles_number: 0,
                        notes: ''
                    })
                }
            },

            addSecondaryKeyword(id) {
                if (this.keywords[id].secondary_keywords.length < 5) {
                    this.keywords[id].secondary_keywords.push({ name: '' })
                }
            },
            deleteKeyword(id) {
                this.keywords.splice(id, 1)
            },
            saveData() {
                this.loading = true

                let keywords = this.keywords.map(keyword => {
                    return {
                        keyword: keyword.main_keyword,
                        secondaryKeywords: keyword.secondary_keywords.filter(
                            secondary_keyword => secondary_keyword.name !== ''
                        ),
                        locale: keyword.lang,
                        groupe_id: keyword.group_id,
                        project_id: this.project.id,
                        nbArticle: keyword.articles_number,
                        notes: keyword.notes,
                        status: 'A faire',
                        date_expiration: new Date(new Date().setMonth(new Date().getMonth() + 5))
                            .toISOString()
                            .split('T')[0]
                    }
                })

                let data = {
                    objects: keywords,
                    bulk: 'true'
                }

                this.axios
                    .post('/crud/preorders', data)
                    .then(() => {
                        this.loading = false
                        this.$refs.toaster.success('Commande envoyée avec succès')
                    })
                    .catch(() => {
                        this.loading = false
                        this.$refs.toaster.error('Une erreur est survenue')
                    })
                    .finally(() => {
                        this.$router.push('/client/preorder')
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fields-wrapper {
        display: flex;
        flex-flow: wrap;
        gap: 1rem;

        & > * {
            flex: 1 1 300px;
        }
    }

    ::v-deep .field {
        display: flex;
        flex-flow: column;
        margin-bottom: 0;
    }
</style>
